<template>
  <v-app>
    <!-- alert begin -->
       <div>
      <v-alert
      v-model="alert"
      border="left"
      dark
      dismissible
      class="alert"
      :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}"
    >
    {{alertMessage}}
        </v-alert>
    </div>
    <!-- alert end  -->

    <div class="row">
      <div class="col-md-6 ml-auto mr-auto">
        <div class="mb-8 mt-8 text-center">
          <h1>Exit Benefits</h1>
        </div>

        <v-form ref="exitRequest" v-model="valid">
          <v-row no-gutters>
          <v-col class="mb-6" >

              <v-btn class="btn-style justify-start text-style" @click.prevent="perSearch = true"  width="100%" height="38" outlined >  <v-icon left>mdi-magnify</v-icon> {{formData.personnelName == "" ? 'Personnel Name' : formData.personnelName}}</v-btn>
            </v-col>
            <v-col cols="6" class="pr-1">
              <TextField  :type="'text'" v-model="formData.onBoardingDate" :disable='true' :label="'OnBoarding Date new'"/>
            </v-col>
            <v-col cols="6" class="pl-1">
              <TextField  :type="'date'" v-model="formData.exitDate" :label="'Exit Date'"/>
            </v-col>
             <v-col cols="6" class="pr-1">
               <TextField  :type="'number'" v-model="exitMonth" :disable='true' :label="'Exit Month'"/>
            </v-col>
            <v-col cols="6" class="pl-1">
               <TextField  :type="'number'" v-model="exitYear" :disable='true' :label="'Exit Year'"/>
            </v-col>
             <v-col cols="6" class="pr-1">
              <TextField  :type="'number'" v-model="formData.salary" :disable='true' :label="'Salary'"/>
            </v-col>

            <v-col cols="6" class="pl-1">
              <TextField  :type="'text'" v-model="formData.workLocation" :disable='true' :label="'Work Location'"/>
            </v-col>
            <v-col cols="6" class="pr-1">
              <TextField   v-model="formData.costCenter" :disable='true' :label="'Cost Center'"/>
            </v-col>
            <v-col cols="6" class="pl-1">
              <SelectField
                :items ="exitReason"
                v-model="formData.exitReasonId"
                :label="'Reason for Exit'"
                :itemText="'name'"
                :itemValue ="'id'"
                :returnObject = true

                />
            </v-col>
            <v-col v-if="formData.exitReasonId && formData.exitReasonId.id === 4" cols="12" class="pr-1">
              <v-radio-group v-model="formData.InLieuOfStatus" :mandatory="false" class="radiogroup" :column="false" :dense="true" :validate-on-blur="true" label="Did personnel give notice?">
                <v-radio style="margin-right:50px" label="Yes" :value="1"></v-radio>
                <v-radio label="No" :value="0"></v-radio>
              </v-radio-group>
            </v-col>

          </v-row>
        </v-form>

        <!-- generate benefit button  -->
         <v-row>
            <v-col cols="12" class="d-flex justify-end">
                <Button :label="'Generate Benefit'"
                :btnType="'Submit'"
                 @onClick="generateBenefit"
                 :disabled="!valid"
                :isLoading="isSavingUser"
                 class="" />
            </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <v-data-table
              :headers="BenefitHeader"
              :items="Benefits"
              >
              </v-data-table>
          </v-col>
        </v-row>
        <v-row no-gutters v-if="totalInvoice !== null">
          <v-col cols="12">
            <v-simple-table  class="table-style">
              <thead class="col-style">
                <tr>
                  <th>Tax Table</th>

                  <th>N</th>
                </tr>
              </thead>

                <tr>
                  <td>Benefits liable to tax</td>

                  <td class="table-td-width ">{{totalInvoice.benefitsLiableToTax}}</td>
                </tr>
                <tr>
                  <td>Relief</td>

                  <td class="table-td-width ">{{totalInvoice.relief}}</td>
                </tr>
                <tr>
                  <td>Taxable Income</td>

                  <td class="table-td-width ">{{totalInvoice.taxableIncome}}</td>
                </tr>
                <tr>
                  <td>PAYE</td>

                  <td class="table-td-width ">{{totalInvoice.paye}}</td>
                </tr>
                <tr class="col-style">
                  <td>Net Payment to Personnel</td>

                  <td class="table-td-width ">{{totalInvoice.netPaymentToPersonnel}}</td>
                </tr>

            </v-simple-table>
          </v-col>
        </v-row>
        <v-row no-gutters  v-if="totalInvoice !== null">
          <v-col cols="12">
            <v-simple-table  class="table-style">
              <thead class="col-style">
                <tr>
                  <th>Contracting Company</th>
                  <th>Rate (%)</th>
                  <th>N</th>
                </tr>
              </thead>

                <tr>
                  <td>Mark-Up</td>
                  <td>{{totalInvoice.markupRate}}</td>
                  <td>{{totalInvoice.markupAmount}}</td>
                </tr>
                <tr>
                  <td>VAT</td>
                  <td>{{totalInvoice.vatRate}}</td>
                  <td>{{totalInvoice.vatAmount}}</td>
                </tr>
                <tr class="col-style">
                  <td>Total Stake</td>
                  <td></td>
                  <td>{{totalInvoice.totalStake}}</td>
                </tr>

            </v-simple-table>
          </v-col>
          <v-col cols="12" class="mt-5">
            <v-simple-table class="table-style">
              <tbody>
                <tr>
                  <td>Total Invoice Amout</td>
                  <td rowspan="2">  </td>
                  <td rowspan="2">  </td>
                  <td class="text-center">{{totalInvoice.invoiceAmount}}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>
        </v-row>
      </div>
    </div>
    <!-- Personnel Search Modal -->
     <Dialog ref="personnelSearch" :title="'Personnel Search'" :width="600">
       <div v-if="personnelLoading" class="text-center">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </div>
    <v-row v-else>
      <v-col cols="12">
         <v-text-field
            v-model="search"
            outlined
            label="Search"
            @blur="personnelSearchFunction"
            dense
          />
      </v-col>
      <v-col cols="12">
        <v-data-table
          dense
          show-select
          v-model="personnel"
          item-key="id"
          :headers="searchHeaders"
          :items="personnelSearch"
          class="elevation-1 row-pointer"
          :hide-default-footer="true"
        ></v-data-table>
      </v-col>
      </v-row>
    <template v-slot:footer>
      <Button :label="'Close'" :btnType="'Cancel'" @onClick="closePersonnelSearchModal" />
    </template>

  </Dialog>

  <v-row>
      <v-col cols="9 mt-5" class="d-flex justify-end">

          <Button
          :label="'Submit'"
          :btnType="'Submit'"
          @onClick="dialog = true"
          :disabled="Benefits.length === 0"
          :isLoading="isSavingUser"
          class="mr-4"
        />

        <Button :label="'Reset'" :btnType="'Cancel'" @onClick="onReset" />

      </v-col>

    </v-row>
     <ConfirmationDialog :title="'Are you sure you want to submit?'" :isLoading="isSavingUser" :dialog="dialog" :btnTitle="'Yes'" @close="dialog = false" @btnAction="onSave"/>
     <PersonnelSearch :dialog="perSearch" @selectPer="selectPersonnel"  @close="perSearch = false"/>
  </v-app>
</template>
<script>
import TextField from '@/components/ui/form/TextField.vue'
import SelectField from '@/components/ui/form/SelectField.vue'
import Dialog from '@/components/ui/Dialog.vue'
import ConfirmationDialog from '@/components/ui/confirmationDialog.vue'
import Button from '@/components/ui/form/Button.vue'
import moment from 'moment'
import { employeeService, benefitService } from '@/services'
import PersonnelSearch from '@/components/ui/PersonnelSearchModal.vue'
// import { CREATE_TERMINALBENEFIT } from '@/store/action-type'
export default {
  components: {
    TextField,
    SelectField,
    Dialog,
    ConfirmationDialog,
    Button,
    PersonnelSearch
  },
  data () {
    return {
      alertMessage: '',
      alert: false,
      alertType: '',
      valid: false,
      perSearch: false,
      search: '',
      dialog: false,
      isSavingUser: false,
      personnelLoading: false,
      formData: {
        personnelName: '',
        employeeId: '',
        onBoardingDate: '',
        onBoardingDate2: '',
        exitDate: '',
        salary: '',
        workLocation: '',
        costCenter: '',
        exitReason: '',
        InLieuOfStatus: 1
      },
      exitReason: [{ id: 1, name: 'Retirement' }, { id: 2, name: 'Death' }, { id: 3, name: 'Redundancy' }, { id: 4, name: 'Resignation' }],

      searchHeaders: [
        {
          text: 'FullName',
          value: 'name'
        },
        {
          text: 'id',
          value: 'id'
        },
        {
          text: 'email',
          value: 'email'
        }
      ],
      personnelSearch: [],
      personnel: [],
      Benefits: [],
      exitMonth: '',
      exitYear: '',
      totalInvoice: null,
      terminalBenefitId: '',
      BenefitHeader: [
        {
          text: 'Compensation',
          value: 'description'
        },
        {
          text: 'UOM',
          value: 'frequency'
        },
        {
          text: 'Multiplier',
          value: 'multiplier'
        },
        {
          text: 'Amount',
          value: 'amount'
        }
      ]
    }
  },
  watch: {
    'formData.exitDate': function (val) {
      this.exitMonth = (new Date(val).getMonth() + 1).toString()
      this.exitYear = new Date(val).getFullYear().toString()
    },
    personnel: function () {
      if (this.personnel.length === 0) {
        this.onReset()
      } else {
        // console.log(this.personnel[0])
        this.formData.personnelName = this.personnel[0].name
        this.formData.salary = this.personnel[0].salary.toString()
        this.formData.workLocation = this.personnel[0].workLocation
        this.formData.costCenter = this.personnel[0].chargCode.toString()
        this.formData.employeeId = this.personnel[0].id
        this.formData.onBoardingDate = this.personnel[0].resumptionDate
        this.formData.onBoardingDate2 = moment(this.personnel[0].resumptionDate).format('MM/DD/YYYY')
        this.search = ''
      }
    }
  },
  methods: {
    openPersonnelSearchModal () {
      this.$refs.personnelSearch.openModal()
    },
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
    },
    closePersonnelSearchModal () {
      this.personnelSearch = []
      this.$refs.personnelSearch.closeModal()
    },
    personnelSearchFunction () {
      this.personnel = []
      if (this.search === '') {
        this.personnelSearch = []
        return
      }
      this.personnelLoading = true
      employeeService.searchPersonnel(this.search).then(result => {
        this.personnelSearch = result.data
      }).catch(() => {
        this.showAlertMessage('Failed to Load Personnel', 'error')
      }).finally(() => {
        this.personnelLoading = false
      })
    },
    selectPersonnel (item) {
      console.log(item)
      this.formData.personnelName = item.name
      this.formData.salary = item.salary.toString()
      this.formData.workLocation = item.workLocation
      this.formData.costCenter = item.chargCode
      this.formData.employeeId = item.id
      this.formData.onBoardingDate = moment(item.resumptionDate).format('MM/DD/YYYY')
      this.search = ''
    },
    generateBenefit () {
      this.Benefits = []
      benefitService.loadBenefits({ EmployeeId: this.formData.employeeId, ExitReasonId: this.formData.exitReasonId.id, InLieuOfStatus: this.formData.InLieuOfStatus, ExitMonth: parseInt(this.exitMonth), ExitDate: this.formData.exitDate, ExitYear: parseInt(this.exitYear) }).then(result => {
        this.Benefits = result.data.exitBenefits
        this.totalInvoice = result.data
        this.terminalBenefitId = result.data.terminalBenefitId
        this.showAlertMessage('Data Generated Successfully', 'success')
      }).catch(error => {
        console.log(error)
      })
    },
    onSave () {
      // const data = [{
      //   employeeId: this.formData.employeeId,
      //   exitDate: this.formData.exitDate,
      //   comments: this.formData.exitReason.name,
      //   exitReasonId: this.formData.exitReason.id,
      //   terminalBenefitItems: []
      // }]

      // this.Benefits.forEach(benefit => {
      //   data[0].terminalBenefitItems.push({ multiplier: benefit.multiplier, SalaryComponentId: benefit.salaryComponentId, Amount: benefit.amount, Comments: '' })
      // })

      // console.log(data)

      // this.$store.dispatch(CREATE_TERMINALBENEFIT, data).then(() => {
      //   this.showAlertMessage('Request creation successful', 'success')
      // }).catch((error) => {
      //   console.log(error)
      //   this.showAlertMessage('Request creation failed', 'error')
      // }).finally(() => {
      //   this.isSavingUser = false
      //   this.onReset()
      // })
      this.isSavingUser = true
      benefitService.submitTerminalBenefit(this.terminalBenefitId).then(() => {
        this.showAlertMessage('Exit Benefit submitted successfully', 'success')
      }).catch((error) => {
        console.log(error)
        this.showAlertMessage('Operation failed, try again', 'error')
      }).finally(() => {
        this.isSavingUser = false
        this.dialog = false
        this.onReset()
      })
    },
    onReset () {
      this.$refs.exitRequest.reset()
      this.formData.personnelName = ''
      this.formData.onBoardingDate = ''
      this.formData.exitDate = ''
      this.formData.salary = ''
      this.formData.workLocation = ''
      this.formData.costCenter = ''
      this.totalInvoice = null
      this.Benefits = []
    }
  }
}
</script>
<style scoped>
.alert {
  position: fixed;
  z-index: 9999;
  top: 5px;
  min-width: 50%;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.col-style {
  background-color: #f3f4f8;
  font-weight: bold;
}
.table-style {
  border: 1px solid;
}
.table-td-width {
      width: 116px;
}
.v-input--selection-controls{
  margin: 0;
  padding: 0;
}
</style>
